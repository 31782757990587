// Core
import Vue from "vue";
import Router from "vue-router";
import VueSweetalert2 from "vue-sweetalert2";
import VueTheMask from "vue-the-mask";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib"; //Globally import VTextField

// Components
import AppView from "../AppView";
import LoginView from "../LoginView";

import Login from "../components/Login";
import Logoff from "../components/Logoff";
import SignUp from "../components/SignUp";
import Home from "../components/Home";
import NotAllowed from "../components/NotAllowed";
import PasswordReset from "../components/PasswordReset";
import NewPassword from "../components/NewPassword";
import Activate from "../components/Activate";

/* Company */
import CompanyHome from "../components/company/CompanyHome";
import CompanyHomeFinance from "../components/company/CompanyHomeFinance";

/* Administrator */
import AdministratorHome from "../components/admin/AdministratorHome";

/* Users */
import UserList from "../components/user/UserList";
import UserEdit from "../components/user/UserEdit";
import UserNew from "../components/user/UserNew";
import UserHome from "../components/user/UserHome";
import UserPermission from "../components/user/UserPermission";

/* Term */
import TermForm from "../components/terms/TermForm";
import TermList from "../components/terms/TermList";
import TermEdit from "../components/terms/TermEdit";

/* Company Group */
import CompanyGroupList from "../components/company-group/CompanyGroupList";
import CompanyGroupForm from "../components/company-group/CompanyGroupForm";

/* Company */
import CompanyList from "../components/company/CompanyList";
import CompanyForm from "../components/company/CompanyForm";

/* Units */
import UnitList from "../components/unit/UnitList";
import UnitForm from "../components/unit/UnitForm";

/* Areas */
import AreaList from "../components/area/AreaList";
import AreaForm from "../components/area/AreaForm";

/* Corporate Areas */
import CorporateAreaList from "../components/corporate-area/CorporateAreaList";
import CorporateAreaForm from "../components/corporate-area/CorporateAreaForm";

/* Modules */
import ModuleList from "../components/modules/ModuleList";
import ModuleForm from "../components/modules/ModuleForm";

/* Strategic Planning */
import StrategicPlanningList from "../components/strategic-planning/StrategicPlanningList";
import StrategicPlanningForm from "../components/strategic-planning/StrategicPlanningForm";
import StrategicPlanningPrint from "../components/strategic-planning/StrategicPlanningPrint";
import StrategicPlanningPrintFrame from "../components/strategic-planning/StrategicPlanningPrintFrame";

/* Perspective */
import PerspectiveList from "../components/perspective/PerspectiveList";
import PerspectiveForm from "../components/perspective/PerspectiveForm";

/* Objective */
import ObjectiveList from "../components/objective/ObjectiveList";
import ObjectiveForm from "../components/objective/ObjectiveForm";

/* Indicator */
import IndicatorList from "../components/indicator/IndicatorList";
import IndicatorForm from "../components/indicator/IndicatorForm";
import IndicatorRegister from "../components/indicator/IndicatorRegister";

/* Metrics */
import MetricsList from "../components/metrics/MetricsList";
import MetricsForm from "../components/metrics/MetricsForm";

/* Plan Action */
import PlanActionList from "../components/plan-action/PlanActionList";
import PlanActionForm from "../components/plan-action/PlanActionForm";
import PlanActionRegister from "../components/plan-action/PlanActionRegister";

/* Base Data */
import BaseDataList from "../components/base-data/BaseDataList";
import BaseDataForm from "../components/base-data/BaseDataForm";

/* Diagnostic */
import DiagnosticList from "../components/diagnostic/DiagnosticList";
import DiagnosticForm from "../components/diagnostic/DiagnosticForm";

/* Range Indicator */
import RangeIndicatorList from "../components/range-indicator/RangeIndicatorList";
import RangeIndicatorForm from "../components/range-indicator/RangeIndicatorForm";

/* Analyze */
import AnalyzeList from "../components/analyze/AnalyzeList";
import AnalyzeForm from "../components/analyze/AnalyzeForm";

/* Process */
import ProcessList from "../components/process/ProcessList";
import ProcessForm from "../components/process/ProcessForm";
import ProgramList from "../components/program/ProgramList";
import ProgramForm from "../components/program/ProgramForm";

/* Contracts */
import ContractsList from "../components/contract/ContractsList";
import ContractsForm from "../components/contract/ContractsForm";

/* Meeting Minutes */
import MeetingMinutesList from "../components/meeting-minutes/MeetingMinutesList";
import MeetingMinutesForm from "../components/meeting-minutes/MeetingMinutesForm";

/* Organization Chart */
import OrganizationChartList from "../components/organization-chart/OrganizationChartList";
import OrganizationChartForm from "../components/organization-chart/OrganizationChartForm";

/* Organization Chart Level */
import OrganizationChartLevelList from "../components/organization-chart-level/OrganizationChartLevelList";
import OrganizationChartLevelForm from "../components/organization-chart-level/OrganizationChartLevelForm";

import OrganizationChartLevelAreaList from "../components/organization-chart-level-area/OrganizationChartLevelAreaList";
import OrganizationChartLevelAreaForm from "../components/organization-chart-level-area/OrganizationChartLevelAreaForm";

import OrganizationChartEmployeeList from "../components/organization-chart-employee/OrganizationChartEmployeeList";
import OrganizationChartEmployeeForm from "../components/organization-chart-employee/OrganizationChartEmployeeForm";

/* Cover */
// import CoverList from "../components/cover/CoverList";
import CoverForm from "../components/cover/CoverForm";

/* FAQ */
import FaqList from "../components/faq/FaqList";
import FaqForm from "../components/faq/FaqForm";

import ProcessPrint from "../components/process-print/ProcessPrint";

import InquiryPerformance from "../components/inquiry-performance/InquiryPerformance";

import Swot from "../components/swot/Swot";

import MeService from "@/app/Services/MeService";

/* Types */
import TypeList from "../components/type/TypeList.vue";
import TypeForm from "../components/type/TypeForm.vue";

const meService = MeService.build();

Vue.use(VueSweetalert2);
Vue.use(Router);
Vue.use(VueTheMask);

Vue.component("v-text-field", VTextField);

Vue.use(VCurrencyField, {
	locale: "pt-BR",
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
});

const router = new Router({
	routes: [
		{
			path: "/logoff",
			component: Logoff,
		},
		{
			path: "/not-allowed",
			component: NotAllowed,
		},
		{
			path: "/",
			component: AppView,
			children: [
				{ path: "/home", name: "Home", component: Home },
				{
					path: "/user/home",
					name: "UserHome",
					component: CompanyHomeFinance,
				},
				{
					path: "/user/access",
					name: "UserHomeAccess",
					component: UserHome,
				},
				{
					path: "/user/permission",
					name: "UserPermission",
					component: UserPermission,
				},
				{
					path: "/company-group/list",
					name: "CompanyGroupList",
					component: CompanyGroupList,
				},
				{
					path: "/company-group/new",
					name: "CompanyGroupForm",
					component: CompanyGroupForm,
				},
				{
					path: "/company-group/edit",
					name: "CompanyGroupEdit",
					component: CompanyGroupForm,
				},

				{
					path: "/company/access",
					name: "CompanyAccess",
					component: CompanyHome,
				},
				{
					path: "/company/home",
					name: "CompanyHome",
					component: CompanyHomeFinance,
				},
				{
					path: "/company/list",
					name: "CompanyList",
					component: CompanyList,
				},
				{
					path: "/company/new",
					name: "CompanyForm",
					component: CompanyForm,
				},
				{
					path: "/company/edit",
					name: "CompanyEdit",
					component: CompanyForm,
				},

				{ path: "/unit/list", name: "UnitList", component: UnitList },
				{ path: "/unit/new", name: "UnitForm", component: UnitForm },
				{ path: "/unit/edit", name: "UnitEdit", component: UnitForm },

				{ path: "/area/list", name: "AreaList", component: AreaList },
				{ path: "/area/new", name: "AreaForm", component: AreaForm },
				{ path: "/area/edit", name: "AreaEdit", component: AreaForm },

				{
					path: "/corporate-area/list",
					name: "CorporateAreaList",
					component: CorporateAreaList,
				},
				{
					path: "/corporate-area/new",
					name: "CorporateAreaForm",
					component: CorporateAreaForm,
				},
				{
					path: "/corporate-area/edit",
					name: "CorporateAreaEdit",
					component: CorporateAreaForm,
				},

				{
					path: "/module/list",
					name: "ModuleList",
					component: ModuleList,
				},
				{
					path: "/module/new",
					name: "ModuleForm",
					component: ModuleForm,
				},
				{
					path: "/module/edit",
					name: "ModuleEdit",
					component: ModuleForm,
				},

				{
					path: "/strategic-planning/list",
					name: "StrategicPlanningList",
					component: StrategicPlanningList,
				},
				{
					path: "/strategic-planning/new",
					name: "StrategicPlanningForm",
					component: StrategicPlanningForm,
				},
				{
					path: "/strategic-planning/edit",
					name: "StrategicPlanningEdit",
					component: StrategicPlanningForm,
				},
				{
					path: "/strategic-planning/copy",
					name: "StrategicPlanningCopy",
					component: StrategicPlanningForm,
				},
				{
					path: "/strategic-planning/print",
					name: "StrategicPlanningPrint",
					component: StrategicPlanningPrint,
				},
				{
					path: "/strategic-planning/print-frame",
					name: "StrategicPlanningPrintFrame",
					component: StrategicPlanningPrintFrame,
				},

				{ path: "/cover/list", name: "CoverList", component: CoverForm },
				// { path: "/cover/new", name: "CoverNew", component: CoverForm },
				// { path: "/cover/edit", name: "CoverEdit", component: CoverForm },

				{
					path: "/perspective/list",
					name: "PerspectiveList",
					component: PerspectiveList,
				},
				{
					path: "/perspective/new",
					name: "PerspectiveForm",
					component: PerspectiveForm,
				},
				{
					path: "/perspective/edit",
					name: "PerspectiveEdit",
					component: PerspectiveForm,
				},

				{
					path: "/objective/list",
					name: "ObjectiveList",
					component: ObjectiveList,
				},
				{
					path: "/objective/new",
					name: "ObjectiveForm",
					component: ObjectiveForm,
				},
				{
					path: "/objective/edit",
					name: "ObjectiveEdit",
					component: ObjectiveForm,
				},

				{
					path: "/indicator/list",
					name: "IndicatorList",
					component: IndicatorList,
				},
				{
					path: "/indicator/new",
					name: "IndicatorForm",
					component: IndicatorForm,
				},
				{
					path: "/indicator/edit",
					name: "IndicatorEdit",
					component: IndicatorForm,
				},

				{
					path: "/metrics/list",
					name: "MetricList",
					component: MetricsList,
				},
				{
					path: "/metrics/new",
					name: "MetricForm",
					component: MetricsForm,
				},
				{
					path: "/metrics/edit",
					name: "MetricEdit",
					component: MetricsForm,
				},

				{
					path: "/indicator/register",
					name: "IndicatorRegister",
					component: IndicatorRegister,
				},

				{
					path: "/plan-action/list",
					name: "PlanActionList",
					component: PlanActionList,
				},
				{
					path: "/plan-action/new",
					name: "PlanActionForm",
					component: PlanActionForm,
				},
				{
					path: "/plan-action/edit",
					name: "PlanActionEdit",
					component: PlanActionForm,
				},

				{
					path: "/plan-action/register",
					name: "PlanActionRegister",
					component: PlanActionRegister,
				},

				{
					path: "/base-data/list",
					name: "BaseDataList",
					component: BaseDataList,
				},
				{
					path: "/base-data/new",
					name: "BaseDataForm",
					component: BaseDataForm,
				},
				{
					path: "/base-data/edit",
					name: "BaseDataEdit",
					component: BaseDataForm,
				},

				{
					path: "/diagnostic/list",
					name: "DiagnosticList",
					component: DiagnosticList,
				},
				{
					path: "/diagnostic/new",
					name: "DiagnosticForm",
					component: DiagnosticForm,
				},
				{
					path: "/diagnostic/edit",
					name: "DiagnosticEdit",
					component: DiagnosticForm,
				},

				{
					path: "/range-indicator/list",
					name: "RangeIndicatorList",
					component: RangeIndicatorList,
				},
				{
					path: "/range-indicator/new",
					name: "RangeIndicatorForm",
					component: RangeIndicatorForm,
				},
				{
					path: "/range-indicator/edit",
					name: "RangeIndicatorEdit",
					component: RangeIndicatorForm,
				},

				{
					path: "/analyze/list",
					name: "AnalyzeList",
					component: AnalyzeList,
				},
				{
					path: "/analyze/new",
					name: "AnalyzeForm",
					component: AnalyzeForm,
				},
				{
					path: "/analyze/edit",
					name: "AnalyzeEdit",
					component: AnalyzeForm,
				},

				{
					path: "/inquiry-performance",
					name: "InquiryPerformance",
					component: InquiryPerformance,
				},

				{ path: "/swot", name: "Swot", component: Swot },

				{
					path: "/process/list",
					name: "ProcessList",
					component: ProcessList,
				},
				{
					path: "/process/new",
					name: "ProcessForm",
					component: ProcessForm,
				},
				{
					path: "/process/edit",
					name: "ProcessEdit",
					component: ProcessForm,
				},

				{
					path: "/process/print",
					name: "ProcessPrint",
					component: ProcessPrint,
				},
				{
					path: "/process/program",
					name: "ProgramList",
					component: ProgramList,
				},
				{
					path: "/process/program/new",
					name: "ProgramForm",
					component: ProgramForm,
				},
				{
					path: "/process/program/edit",
					name: "ProgramEdit",
					component: ProgramForm,
				},

				{
					path: "/contracts/list",
					name: "ContractsList",
					component: ContractsList,
				},
				{
					path: "/contracts/new",
					name: "ContractsForm",
					component: ContractsForm,
				},
				{
					path: "/contracts/edit",
					name: "ContractsEdit",
					component: ContractsForm,
				},

				{
					path: "/process/meeting-minutes",
					name: "MeetingMinutesList",
					component: MeetingMinutesList,
				},
				{
					path: "/process/meeting-minutes/new",
					name: "MeetingMinutesForm",
					component: MeetingMinutesForm,
				},
				{
					path: "/process/meeting-minutes/edit",
					name: "MeetingMinutesEdit",
					component: MeetingMinutesForm,
				},

				{
					path: "/admin/home",
					name: "AdministratorHome",
					component: AdministratorHome,
				},
				{ path: "/user/list", name: "UserList", component: UserList },
				{ path: "/user/edit", name: "UserEdit", component: UserEdit },
				{ path: "/user/new", name: "UserNew", component: UserNew },
				{ path: "/term/new", name: "TermForm", component: TermForm },
				{ path: "/term/list", name: "TermList", component: TermList },
				{ path: "/term/edit", name: "TermEdit", component: TermEdit },

				{
					path: "/organization-chart/list",
					name: "OrganizationChartList",
					component: OrganizationChartList,
				},
				{
					path: "/organization-chart/new",
					name: "OrganizationChartNew",
					component: OrganizationChartForm,
				},
				{
					path: "/organization-chart/edit",
					name: "OrganizationChartEdit",
					component: OrganizationChartForm,
				},

				{
					path: "/organization-chart/level/list",
					name: "OrganizationChartLevelList",
					component: OrganizationChartLevelList,
				},
				{
					path: "/organization-chart/level/new",
					name: "OrganizationChartLevelNew",
					component: OrganizationChartLevelForm,
				},
				{
					path: "/organization-chart/level/edit",
					name: "OrganizationChartLevelEdit",
					component: OrganizationChartLevelForm,
				},

				{
					path: "/organization-chart/area/list",
					name: "OrganizationChartLevelAreaList",
					component: OrganizationChartLevelAreaList,
				},
				{
					path: "/organization-chart/area/new",
					name: "OrganizationChartLevelAreaNew",
					component: OrganizationChartLevelAreaForm,
				},
				{
					path: "/organization-chart/area/edit",
					name: "OrganizationChartLevelAreaEdit",
					component: OrganizationChartLevelAreaForm,
				},

				{
					path: "/organization-chart/employee/list",
					name: "OrganizationChartEmployeeList",
					component: OrganizationChartEmployeeList,
				},
				{
					path: "/organization-chart/employee/new",
					name: "OrganizationChartEmployeeNew",
					component: OrganizationChartEmployeeForm,
				},
				{
					path: "/organization-chart/employee/edit",
					name: "OrganizationChartEmployeeEdit",
					component: OrganizationChartEmployeeForm,
				},

				{
					path: "/faq/list",
					name: "FaqList",
					component: FaqList,
				},
				{
					path: "/faq/new",
					name: "FaqNew",
					component: FaqForm,
				},
				{
					path: "/faq/edit",
					name: "FaqEdit",
					component: FaqForm,
				},

				{ path: "/type/list", name: "TypeList", component: TypeList },
				{ path: "/type/new", name: "TypeForm", component: TypeForm },
				{ path: "/type/edit", name: "TypeEdit", component: TypeForm },
			],
		},
		{
			path: "/#/",
			component: LoginView,
			children: [
				{ path: "/login", name: "Login", component: Login },
				{
					path: "/auto-login/:hash",
					name: "AutoLogin",
					component: Login,
					props: true,
				},
				{ path: "/sign-up", name: "SignUp", component: SignUp },
				{
					path: "/password-reset",
					name: "PasswordReset",
					component: PasswordReset,
				},
				{
					path: "/new-password",
					name: "NewPassword",
					component: NewPassword,
				},
				{ path: "/activate", name: "Activate", component: Activate },
			],
		},
	],
	scrollBehavior: function () {
		return {
			x: 0,
			y: 0,
		};
	},
});

router.beforeEach((to, from, next) => {
	const publicPages = [
		"/login",
		"/logoff",
		"/sign-up",
		"/forgot-password",
		"/change-password",
		"/not-allowed",
		"/password-reset",
		"/new-password",
		"/activate",
	];
	const authRequired = !publicPages.includes(to.path);
	const autologin = "/auto-login/";
	const user = JSON.parse(localStorage.getItem("user"));
	const toPath = to.fullPath;
	const instance = router.app;

	if (to.path.indexOf(autologin) >= 0) {
		localStorage.removeItem("user");
		localStorage.removeItem("permissions");
	} else if (
		to.path.indexOf(autologin) < 0 &&
		authRequired &&
		!user &&
		toPath !== "/login"
	) {
		instance.$router.push({ name: "Login", params: { cleanCache: true } });
		next();
	} else if (!user && toPath === "/") {
		instance.$router.push({ name: "Login", params: { cleanCache: true } });
		next();
	} else if (user) {
		const permissions = localStorage.getItem("permissions");

		if (permissions === null) {
			meService.me().then((response) => {
				if (
					!publicPages.includes(to.path) &&
					!response.permissions.allowedRoutes.includes(to.path)
				) {
					instance.$router.push("/not-allowed");
					next();
				}
			});
		} else {
			const parse = JSON.parse(permissions);

			if (
				!publicPages.includes(to.path) &&
				!parse.allowedRoutes.includes(to.path)
			) {
				instance.$router.push("/not-allowed");
				next();
			}
		}
	}

	next();
});

export default router;
