<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal">Filtrar áreas</span>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn
							v-show="!view"
							class="white--text mr-2"
							depressed
							color="#34bfa3"
							@click="$router.push('/area/new')"
							>+ Novo</v-btn
						>
						<v-btn class="white--text" depressed color="info" @click="filter()"
							>Buscar</v-btn
						>
					</v-card-title>
					<v-spacer></v-spacer>
					<v-divider></v-divider>
					<v-card-text>
						<v-row class="pl-6">
							<v-col cols="12" md="4">
								<v-select
									v-model="filters.company"
									:items="companies"
									item-text="DESEMP"
									item-value="CODEMP"
									label="Empresa"
									:loading="loadingCompany"
									@change="getUnits()"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<v-select
									v-model="filters.unit"
									:items="units"
									item-text="DESUNI"
									item-value="CODUNI"
									label="Unidade"
									:loading="loadingUnit"
								></v-select>
							</v-col>
							<v-col cols="12" md="4" sm="12" xs="12" lg="4">
								<v-text-field
									v-model="filters.description"
									label="Descrição"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="pagination.itemsPerPage"
						v-bind:loading="loading"
						loading-text="Aguarde...carregando"
						:hide-default-footer="true"
					>
						<template v-slot:body="{ items }">
							<tr v-for="item in items" :key="item.CODARE">
								<td v-if="!view">
									<v-btn @click="edit(item.CODARE)" icon>
										<v-icon style="color: orange">mdi-pencil</v-icon>
									</v-btn>
								</td>
								<td v-else>&nbsp;</td>
								<td>{{ item.CODARE }}</td>
								<td>{{ item.company.DESABR }}</td>
								<td>{{ item.unit.DESABR }}</td>
								<td>{{ item.DESARE }}</td>
								<td v-if="!view">
									<v-btn @click="remove(item.CODARE)" icon>
										<v-icon style="color: red">mdi-delete</v-icon>
									</v-btn>
								</td>
								<td v-else>
									<v-btn @click="edit(item.CODARE)" icon>
										<v-icon>mdi-eye</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<pagination
						v-model="pagination"
						@change="loadData"
						:loading="loading"
					/>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import AreaService from "../../app/Services/AreaService";
import CompanyService from "../../app/Services/CompanyService";
import UnitService from "../../app/Services/UnitService";
import pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SortableMixin from "@/components/pagination/sort/SortableMixin";

const areaService = AreaService.build();
const companyService = CompanyService.build();
const unitService = UnitService.build();

export default {
	name: "AreaList",
	components: { pagination },
	mixins: [PaginationMixin, SortableMixin],
	computed: {},
	async mounted() {
		const userData = JSON.parse(localStorage.getItem("user"));

		if (userData) {
			this.view = userData.user.TIPUSU === "P";
		}

		Promise.all([
			this.getCompanies(),
			this.getUnits(),
			this.loadData(),
		])
	},
	data: () => ({
		loading: false,
		view: false,
		filters: {},
		headers: [
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%",
			},
			{
				text: "Código",
				align: "left",
				sortable: true,
				value: "CODARE",
				width: "10%",
			},
			{
				text: "Empresa",
				align: "left",
				sortable: true,
				value: "company.DESABR",
				width: "10%",
			},
			{
				text: "Unidade",
				align: "left",
				sortable: true,
				value: "unit.DESABR",
				width: "10%",
			},
			{
				text: "Nome",
				align: "left",
				sortable: true,
				value: "DESARE",
			},
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%",
			},
		],
		companies: [
			{
				CODEMP: "",
				DESEMP: "Todos",
			},
		],
		units: [
			{
				CODUNI: "",
				DESUNI: "Todos",
			},
		],
		loadingCompany: false,
		loadingUnit: false,
		items: [],
	}),
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		async loadData() {
			try {
				this.loading = true;

				const filter = this.filters;
				const query = {
					params: {
						filter,
						...this.pagination,
					},
				};

				const response = await areaService.index({ query });

				this.items = response.data;

				this.paginate(response);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
				this.defaultCatchError(e);
			} finally {
				this.loading = false;
			}
		},
		filter() {
			this.searching = true;
			this.items = [];
			this.pagination.page = 1;

			this.loadData();
		},
		edit(id) {
			this.$router.push({
				name: "AreaEdit",
				params: {
					id: id,
					view: this.view,
				},
			});
		},
		remove(id) {
			areaService
				.destroy({
					id: id,
				})
				.then(
					() => {
						this.toast("success", "Area removida com sucesso!");

						this.loadData();
					},
					(error) => {
						switch (error.response.status) {
							case 422:
								this.$toasted.info(
									"Área vinculada com Plano de Ação, não pode excluir!",
									{ icon: "mdi-information" }
								);
								break;

							default:
								this.$toasted.info(error.response.data, {
									icon: "mdi-information",
								});
								break;
						}
					}
				);
		},
		getCompanies() {
			this.loadingCompany = true;
			return companyService.search({}).then((response) => {
				this.companies = response;

				this.companies.unshift({
					CODEMP: "",
					DESEMP: "Todos",
				});

				if (this.companies.length === 1) {
					this.filters.company = this.companies[0].CODEMP;
				}

				this.loadingCompany = false;
			});
		},
		getUnits() {
			const search = {};

			this.units = [];
			this.loadingUnit = true;

			if (this.filters.company) {
				search.company = this.filters.company;
			}

			return unitService.search(search).then((response) => {
				this.units = response;

				this.units.unshift({
					CODUNI: "",
					DESUNI: "Todos",
				});

				if (this.units.length === 1) {
					this.filters.unit = this.units[0].CODUNI;
				}

				this.loadingUnit = false;
			});
		},
	},
};
</script>
