import Rest from "../Base/Rest";

/**
 * @typedef {ContractsService}
 */
export default class ContractsService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = "/contracts";
}
